$tds: true;
@import 'styles/variables';
.header {
  padding: 22px 30px 7px;
  .title {
    line-height: 36px;
    margin: 0;
    font-size: 20px;
    color: $gray-darker;
  }
}

.tab {
  :global {
    .ui.secondary.menu {
      padding: 5px 30px 0 !important;
      font-size: 16px;
      min-height: unset;
      > .item {
        line-height: 24px;
        padding: 8px 0;
        margin: 0 32px 0 0 !important;
        min-width: unset;
      }
    }
  }
}
