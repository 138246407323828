$tds: true;
.section {
  display: flex;
  flex-direction: column;
  padding-top: 1.5em!important;
  padding-bottom: 1.5em!important;
  &:first-child {
    padding-top: .5em !important;
  }
  &:last-child {
    padding-bottom: .5em !important;
  }

  :global {
    .ui.header {
      padding: .2em 0;
      margin-bottom: 1em;
    }
    h4.ui.header {
      font-size: 16px;
    }
  }
}

.content {
  flex: 1;
}
