$tds: true;
@import 'styles/variables';
$menu-item-padding: 7px;

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: $section-bg;

  .homeLink {
    min-width: 45px;
    @include media-tablet() {
      min-width: 230px;
    }
  }
}
.content {
  width: 100%;
  z-index: 999;
  flex-wrap: wrap;
  border-bottom: 1px solid $muted-separator-color;
  padding: 0 $padding;
  @include media-tablet {
    padding: 0 $padding-lg;
  }
  align-items: stretch;

  .menuItem {
    & + .menuItem {
      margin-left: 3px;
    }
    &:first-child {
      margin-left: -$menu-item-padding;
    }
    &:last-child {
      margin-right: -$menu-item-padding;
    }
  }
}
.logo {
  width: $menu-width;
  text-align: center;
  font-size: 20px;
  line-height: $compact-header-height;
  @include media-tablet {
    line-height: $header-height;
  }
  margin-bottom: -1px;
  color: #fff;
  background-color: $brand-primary-text;
  &:hover,
  &:active {
    color: #fff;
    background-color: lighten($color: $brand-primary-text, $amount: 5%);
  }
}
.menuItemTrigger {
  display: inline-block;
  cursor: pointer;
  line-height: $compact-header-height;
  @include media-tablet {
    line-height: $header-height;
  }
  padding: 0 $menu-item-padding;
  min-width: 3em;
  text-align: center;
  color: $muted-color;
  &:hover,
  &:active {
    color: darken($color: $muted-color, $amount: 40%);
    background-color: $section-bg;
  }
  &.dropdown:after {
    content: ' ';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 4px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

.avatar {
  vertical-align: middle;
  border-radius: 100%;
  margin-right: 4px;
}
.username {
  display: none;
  @include media-tablet {
    display: inline;
  }
}

.appSelect {
  max-height: 90vh;
  max-width: 300px;
  min-width: 250px !important;
  overflow: auto;
}

.app {
  :global {
    .label,
    kbd {
      margin: -1em 0 -1em $margin-sm;
    }
  }
  &.currentApp {
    display: inline-flex;
  }
}

.appName {
  overflow: hidden;
  text-overflow: ellipsis;
}

.active {
  background: $section-bg;

  .appName {
    color: #000;
  }
}
