$tds: true;
@import 'styles/variables';

.sticky.fillParent {
  overflow: auto;
  > :global(.ui.active.tab) {
    flex: 1;
    overflow: hidden auto;
  }
}

.borderless {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  position: relative;

  &.sticky {
    > :global(.ui.secondary.menu) {
      position: sticky;
    }
  }

  &.padding {
    > :global(.ui.secondary.menu) {
      padding: 0 $padding;
      @include media-tablet {
        padding: 0 $padding-lg;
      }
    }
  }

  &:not(.padding) {
    > :global(.ui.segment.tab) {
      padding-left: 0;
      padding-right: 0;
    }

    &:last-child {
      > :global(.ui.segment.tab) {
        padding-bottom: 0;
      }
    }
  }

  > :global(.ui.secondary.menu) {
    border: none;
    box-shadow: inset 0 -1px $muted-separator-color;
    top: 0;
    z-index: 50;
    background-color: white;
    widows: 100%;
    margin: 0;
    overflow: auto;
    & > :global(.item) {
      color: if($tds, $tds-grey6, $muted-link-color);
      border: none;
      justify-content: center;
      min-width: 4em;
      margin: 0;
      &:not(:last-child) {
        margin-right: $margin;
      }
      padding: 0 $margin 1px;
      line-height: ($compact-nav-height - 1px);
      @include media-tablet() {
        line-height: ($nav-height - 1px);
      }
      &:global(.active) {
        font-weight: normal;
        position: relative;
        &::before {
          content: '';
          display: block !important;
          position: absolute;
          left: 0;
          right: 0;
          top: auto;
          width: auto;
          bottom: -0px;
          height: 0px;
          border-bottom: 2px solid;
        }
      }
    }
  }
  > :global(.ui.active.tab) {
    border: none;
  }
  > :global(.ui.segment.tab) {
    margin-top: 0;
    @include container;
    border-radius: 0;
    box-shadow: none;
    &:empty {
      display: none;
    }
  }
}
