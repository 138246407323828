$tds: true;
@use "sass:math";

@import 'styles/variables';
.dateTimeInputWrapper {
  position: relative;
}
.dateTimeInputSelector {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: math.div(5em, 7);
  z-index: 999; // 绝对上层

  &.rightAligned {
    left: auto;
    right: 0;
  }
}
