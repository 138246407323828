$tds: true;
@import 'styles/variables';
.envList {
  margin-top: 0 !important;
  margin-left: 2rem !important;
  margin-bottom: 0.5em !important;
}
.radioItem {
  min-width: 8em;
  font-size: 0.9em;
}

.breadcrumb {
  margin-bottom: 1em !important;
}
.statToolbar {
  margin-bottom: $margin-xl;
}

.instanceName {
  font-size: 1.4em;
}
