$tds: true;
@import 'styles/variables';
.noData {
  font-size: 0.9em;
  color: $muted-color;
  min-height: 15em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noDataHeader {
  font-weight: normal !important;
}
