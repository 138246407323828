$tds: true;
@import 'styles/variables';

.toolbar {
  margin-bottom: $margin-xl;
  > * {
    margin-left: $margin;
  }
}

.item {
  padding: 0.5em 0;
  vertical-align: top;
  > h4 {
    margin: 1em 0;
    text-align: center;
  }
}
.grid {
  .item {
    @include media-tablet {
      display: inline-block;
      width: 50%;
    }
  }
}
.detailedMetricsArea {
  margin: $margin-lg (-$padding);
  @include media-tablet {
    margin: $margin-lg (-$padding-lg);
  }
  &:last-child {
    margin-bottom: 0;
  }
  @include container;
  background-color: $muted-section-bg;
}
