$tds: true;
@import 'styles/variables';

.breadcrumb {
  margin-bottom: 1em !important;
}

.statToolbar {
  margin-bottom: $margin-xl;
}
