$tds: true;
@import 'styles/variables';

.inline {
  display: inline-block;
  // min-width: 20em;
}
.block{
  display: block;
}
