$tds: true;
@import 'styles/variables';

.logo {
  display: block;
  text-align: center;
  font-size: 20px;
  line-height: $compact-header-height;
  @include media-tablet {
    line-height: $header-height;
  }
  &:not(.transparent) {
    color: #fff;
    background-color: $brand-primary-text;
    &:hover,
    &:active {
      color: #fff;
      background-color: lighten($color: $brand-primary-text, $amount: 5%);
    }
  }
}
