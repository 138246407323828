$tds: true;
@import 'styles/variables';

.statToolbar {
  margin-bottom: $margin-xl;
}

$item-basic-width: 450px;
// why 450px because 280/0.618 ≈ 450 280 is height
.grid {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-bottom: 2em;
    flex: 1 0 25%;
    // why set min-width
    // https://www.w3.org/TR/css-flexbox-1/#min-size-auto
    min-width: $item-basic-width;
    &:empty {
      height: 0;
    }
  }
}
.list {
  .item {
    margin-bottom: 2em;
    &:empty {
      display: none;
    }
  }
}

.radioItem {
  min-width: 10em;
  font-size: 0.9em;
}

.instanceCard {
  width: 360px !important;
  .header {
    display: flex !important;
    align-items: center;
  }
  .quota {
    margin-left: 0.5em !important;
  }
  .space {
    display: block;
    flex: 1;
  }
  .instanceDetails {
    display: flex;
    justify-content: space-between;
  }
  .operations {
    padding: 0.65em !important;
    :global(.ui.button) {
      margin: 0.1em;
    }
  }
}

.table {
  th {
    position: static !important;
  }
}

.instanceName {
  font-size: 1.4em;
}

.chartContainer {
  padding: 0.5em 0;
  vertical-align: top;
  > h4 {
    margin: 1em 0;
    text-align: center;
  }
}
