$tds: true;
@import 'styles/variables';
.homeLink {
  min-width: 45px;
  // cover the left padding of the header content
  margin-right: -21px;
}

.loading {
  opacity: 0;
}
.TDSContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.TDScontent {
  display: flex;
  flex-direction: column;
  height: 100%;

  :global(> .tab_container) {
    flex-grow: 0 !important;
  }
}
