$tds: true;
@use "sass:math";

@import 'styles/variables';

html,
body,
#root {
  height: 100%;
  content: $tds;
}

kbd {
  color: #444d56;
  background-color: $code-bg;
  border-radius: 4px;
  box-shadow: inset 0 -1px 0 #d1d5da;
  border: 1px solid #ddd;
  display: inline-block;
  padding: 0 0.5em;
  line-height: 1.6em;
}

code {
  padding: 2px 4px;
  font-size: 95%;
  color: $code-color;
  background-color: $code-bg;
  border-radius: 4px;
}
pre {
  font-size: 85%;
  line-height: 1.45;
  background-color: $muted-section-bg;
  padding: $pre-padding;
  border-radius: 4px;
  overflow: auto;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  word-wrap: normal;
  background-color: transparent;
  border-radius: 0;
}
// fieldset 目前仅用来控制大范围的表单 disable
// 因此将其重置为不可见的样式
fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.ui.menu .item > i.icon {
  height: auto;
}

.ui.message:not(.attached) {
  word-break: break-word;
  min-height: fit-content;
}
.ui.message {
  a {
    font-weight: bold;
  }

  .content {
    // https://weblog.west-wind.com/posts/2016/feb/15/flexbox-containers-pre-tags-and-managing-overflow
    min-width: 0;
  }
}

.ui.search > .ui.input > input:focus {
  z-index: 0;
}

.ui.cards + p {
  margin-top: 1em;
}

.help-block {
  margin-top: 0.5em;
  color: $muted-color;
  font-size: math.div(13em, 14);
}
ul,
ol {
  &.help-block {
    padding-left: 2em;
  }
}
.checkbox-sub-content,
.ui.checkbox + .help-block {
  padding-left: (math.div(13em, 7) * math.div(14, 13));
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.text-muted {
  color: $muted-color;
}
.text-warning {
  color: $brand-warning;
}
.text-placeholder {
  color: $muted-color;
  opacity: 0.5;
}
.text-danger {
  color: $brand-danger;
}
.text-success {
  color: $brand-success;
}
.text-nowrap {
  white-space: nowrap;
}

.auto-width {
  width: auto !important;
}
.link {
  cursor: pointer;
  color: $link-color;
  &:hover {
    color: $link-color-hover;
  }
}

.commit-sha {
  padding: 0.2em 0.4em;
  font-family: $font-family-monospace;
  font-size: 90%;
  font-weight: 400;
  background-color: $section-bg;
  border: 1px solid #eaecef;
  border-radius: 0.2em;
  margin-right: 0.25rem;
}

.fill-space {
  display: flex;
  align-items: center;
  > .space {
    display: block;
    flex: 1;
  }
}

.spec-value {
  font-family: $emphasis-figure-font;
  font-size: math.div(21em, 14);
  line-height: 1.4285em;
  font-weight: if($tds, 600, normal);
}
.spec-container {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #ddd;
  border-radius: math.div(4em, 14);
  padding: math.div(10em, 14);
}

.strong-section {
  background-color: $muted-section-bg;
  @include container;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.splash {
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ui.dropdown .menu > .header {
  font-weight: normal;
  color: $muted-link-color;
  margin: 1rem 0 0.5rem;

  &:first-child {
    margin-top: 0.5rem;
  }
}

// label 应该完全显示内容
.ui.label {
  min-width: max-content;
}
.ui.button:last-child {
  margin-right: 0;
}
.ui.negative.message a {
  color: $brand-danger;
}

.inlineField {
  display: inline-block;
  min-width: 200px;
}

.ui.sortable.table thead th:hover {
  background-color: rgb(249, 250, 251);
}
.ui.sortable.table thead th {
  border-left: none;
}
.ui.sortable.table thead th.sorted {
  background: #f9fafb !important;
  cursor: pointer;
}

.ui.input > input:focus {
  z-index: inherit !important;
}

.ui.form {
  .fieldItem {
    margin-top: $margin !important;
  }
}

.ui.modal > .header {
  word-break: break-all;
}
