$tds: true;
@import 'styles/variables';
.container {
  padding: 0.4em;
  right: 0.25em;
  @include media-tablet {
    width: 400px;
  }
  :global {
    .Toastify__toast {
      padding: 0;
      min-height: 1em;
      @if not($tds) {
        border-radius: 0;
      } @else {
        border-radius: 4px;
      }
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
    }
    .Toastify__toast-body {
      padding: 0;
    }
    .ui.message {
      word-break: break-word;
      .header:not(.ui) {
        font-size: 16px;
        margin: 0;
        + p {
          margin-top: 0.75em;
        }
      }
    }
  }
}
