$tds: true;
@import 'styles/variables';
.container {
  display: inline-block;
  min-width: fit-content;
  cursor: pointer;
  .selected {
    padding: $margin-lg 0;
    display: inline-block;
    :global(.dropdown) {
      position: relative;
      width: auto;
      font-size: 0.85714286em;
      margin: 0em 0em 0em 0.25em;
      font-family: 'Dropdown';
      line-height: 1;
      height: 1em;
      width: 1.23em;
      backface-visibility: hidden;
      font-weight: normal;
      font-style: normal;
      text-align: center;
    }
  }

  &.right {
    float: right;
  }
}
.panel {
  display: flex;
  flex-direction: row;
  position: relative;

  .dateMenu {
    padding: 0;
    height: fit-content;
    max-height: 100%;
    overflow: auto;
    margin-left: 0px !important;
    margin-bottom: 0px !important;
    z-index: 999;
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    width: 10em !important;
    max-height: 340px;
    overflow: auto;
    :global {
      .item {
        border-radius: 0 !important;
      }
      .item::before {
        display: none;
      }
      .item:hover {
        background-color: rgba(0, 0, 0, 0.03) !important;
      }
    }
  }

  .rangeContainer {
    :global {
      .rdrCalendarWrapper {
        border: none;
      }
      .rdrMonth {
        padding-bottom: 0.5em;
      }
    }
  }

  &.left {
    .rangeContainer {
      border-left: 1px solid rgba(0, 0, 0, 0.05);
    }
  }

  &.right {
    .dateMenu {
      order: 1;
    }
    .rangeContainer {
      border-right: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
}

.popContent {
  text-align: center;
}

.timeInputGroups {
  padding: 0.833em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .timeInput {
    width: 100px;
    :global {
      .ui.input {
        width: 100%;
      }
    }
  }
  .submitButton {
    min-width: 6em;
  }
}
