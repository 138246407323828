$tds: true;
@import 'styles/variables';

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  &.embed {
    display: flex;
    flex-direction: column;
    @include media-desktop {
      flex-direction: row;
    }
  }
}

.content {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;

  iframe {
    display: block;
    border: none;
    height: 100%;
    width: 100%;
  }
}
